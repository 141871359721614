<template>
  <main style="padding-top: 50px">
    <BranchesMap
      style="padding-top: 50px"
      :desc="$t('home.branches.body')"
      :title="$t('home.branches.title')"
    />
  </main>
</template>

<script>
import BranchesMap from '@/components/BranchesMap/BranchesMap.vue'
export default {
  name: 'Branches page',
  components: {
    BranchesMap,
  },
  metaInfo() {
    return {
      title: this.$t('meta.pages.branches.title') + ' | Team Pay',
      description: this.$t('meta.pages.branches.description'),
    }
  },
}
</script>

<style></style>
